export {
    filterPlansForPolicy,
    pruneCancelledLives,
    pruneCancelledPolicies
} from './src/domain/alteration/alteration-kit/loose-end-kit'

export * from './src/domain/models/geo-location/Address'
export * from './src/domain/models/geo-location/GeoLocation'
export * from './src/domain/models/geo-location/uk-regions'
export * from './src/domain/models/geo-location/uk-county-region'
export * from './src/domain/models/LifecycleStatus'
export * from './src/domain/models/Account'
export * from './src/domain/models/Subscription'
export * from './src/domain/models/Plan'
export * from './src/domain/models/PlanBenefit'
export * from './src/domain/models/Policy'
export * from './src/domain/models/Life'
export * from './src/domain/models/LifeBenefit'
export * from './src/domain/models/Premium'
export * from './src/domain/models/PaymentDetails'
export * from './src/domain/models/PaymentTransaction'
export * from './src/domain/models/CancellationType'
export * from './src/domain/models/Company'
export * from './src/domain/models/User'
export * from './src/domain/models/SubscriptionQuoteRequest'
export * from './src/domain/models/SubscriptionQuoteResponse'
export * from './src/domain/models/Excess'

export * from './src/domain/models/AccountSubscriptionDto'

export * from './src/domain/models/InviteCompanyAdminRequest'

export * from './src/domain/models/doc-data-models/CompanyPolicyDocData'
export * from './src/domain/models/doc-data-models/MemberPolicyDocData'
export * from './src/domain/models/ContentBucketResponse'



export * from './src/domain/models/email-data-models/CancellationEmailData'
export * from './src/domain/models/email-data-models/CompanyPolicyDocChangesEmailData'
export * from './src/domain/models/email-data-models/CompanyPolicyDocEmailData'
export * from './src/domain/models/email-data-models/IndividualRenewalEmailData'
export * from './src/domain/models/email-data-models/InviteSmeAdminEmailData'
export * from './src/domain/models/email-data-models/PolicyDocChangesEmailData'
export * from './src/domain/models/email-data-models/PolicyDocEmailData'
export * from './src/domain/models/email-data-models/UserCancellationEmailData'

export * from './src/domain/alteration/agent/SubscriptionAlterationAgent'
export * from './src/domain/alteration/agent/LifeAlterationAgent'
export * from './src/domain/alteration/agent/PolicyAlterationAgent'
export * from './src/domain/alteration/agent/PlanAgent'



export * from './src/domain/alteration/reconciliation/gather-alterations/gatherSubscriptionAlterations'
export * from './src/domain/alteration/reconciliation/valid-alterations/cancellation'
export * from './src/domain/alteration/reconciliation/valid-alterations/transfer'
export * from './src/domain/alteration/reconciliation/valid-alterations/reactivation'
export * from './src/domain/alteration/reconciliation/valid-alterations/renewal'

export * from './src/domain/alteration/reconciliation/reconcileSubscriptionModelAlteration'
export * from './src/domain/alteration/reconciliation/reconcilePolicyModelAlteration'
export * from './src/domain/alteration/reconciliation/reconcileLifeModelAlteration'
export * from './src/domain/alteration/reconciliation/reconcileBenefitModelAlteration'

export * from './src/core-events/docs/EmailSendStatus'

export * from './src/domain/alteration/quote/apply-quotes'
export * from './src/domain/alteration/quote/map-to-quote'
export * from './src/domain/alteration/quote/select-for-quote'


export * from './src/core-events/life-repo/LifeRepoUpsertRequest'
export * from './src/core-events/life-repo/LifeRepoUpserted'

export * from './src/core-events/notifications/gatherSubscriptionNotifications'
export * from './src/core-events/notifications/notification-kit'
export * from './src/core-events/notifications/notification-types'
export * from './src/core-events/notifications/SubscriptionActivatedNotification'
export * from './src/core-events/notifications/SubscriptionAlteredNotification'
export * from './src/core-events/notifications/SubscriptionCancelledNotification'
export * from './src/core-events/notifications/LifeActivatedNotification'
export * from './src/core-events/notifications/LifeAlteredNotification'
export * from './src/core-events/notifications/LifeCancelledNotification'

export * from './src/core-events/subscription-lifecycle/AddLifeRequest'
export * from './src/core-events/subscription-lifecycle/CancelLifeRequest'
export * from './src/core-events/subscription-lifecycle/CancelPolicyRequest'
export * from './src/core-events/subscription-lifecycle/CancelSubscriptionRequest'
export * from './src/core-events/subscription-lifecycle/ChangedLife'
export * from './src/core-events/subscription-lifecycle/CheckComplianceRequest'
export * from './src/core-events/subscription-lifecycle/IntercomEventRequest'
export * from './src/core-events/subscription-lifecycle/PolicyDocRequest'
export * from './src/core-events/subscription-lifecycle/PolicyDocSent'
export * from './src/core-events/subscription-lifecycle/PolicyReposCreated'
export * from './src/core-events/subscription-lifecycle/PolicyReposRequest'
export * from './src/core-events/subscription-lifecycle/SubscriptionActivated'
export * from './src/core-events/subscription-lifecycle/SubscriptionAltered'
export * from './src/core-events/subscription-lifecycle/SubscriptionCancelled'
export * from './src/core-events/subscription-lifecycle/SubscriptionCreated'

export * from './src/core-events/docs/activation/CompanyPolicyDocSendRequest'
export * from './src/core-events/docs/activation/IndividualPolicyDocSendRequest'
export * from './src/core-events/docs/activation/MemberPolicyDocSendRequest'

export * from './src/core-events/docs/alteration/CompanyPolicyAlterationDocSendRequest'
export * from './src/core-events/docs/alteration/IndividualPolicyDocAlterationSendRequest'
export * from './src/core-events/docs/alteration/MemberPolicyDocAlterationSendRequest'

export * from './src/core-events/docs/cancellation/CompanyCancellationEmailSendRequest'
export * from './src/core-events/docs/cancellation/IndividualCancellationEmailSendRequest'
export * from './src/core-events/docs/cancellation/MemberCancellationEmailSendRequest'

export * from './src/core-events/kyc/company/OwnerSanctionsCheckRequest'
export * from './src/core-events/kyc/IdCheckEvent'
export * from './src/core-events/kyc/IdCheckFailed'
export * from './src/core-events/kyc/IdCheckFlagged'
export * from './src/core-events/kyc/IdCheckPassed'
export * from './src/core-events/kyc/IdCheckRequest'
export * from './src/core-events/kyc/IndividualSanctionsCheckRequest'
export * from './src/core-events/kyc/SanctionsCheckEvent'
export * from './src/core-events/kyc/SanctionsCheckFailed'
export * from './src/core-events/kyc/SanctionsCheckFlagged'
export * from './src/core-events/kyc/SanctionsCheckPassed'

export * from './src/core-events/subscription-lifecycle/IntercomEventRequest'


export * from './src/core-events/docs/reissue/SendIndividualPolicyDocReIssueRequest'
export * from './src/domain/alteration/alteration-kit/subscription-alteration-types'
export * from './src/domain/mapping/mapToLegacyAccount'

export * from './test/TestData'
export * from './test/Assertions'


export * from './src/SubscriptionStackApiDefinition'


export * from './src/domain/models/QuoteSummary'
export * from './src/domain/mapping/quoteToQuoteSummaryRecord'
export * from './src/domain/relationships/QuoteRelationshipDefinition'

export * from './src/domain/models/SubscriptionSummary'
export * from './src/domain/relationships/SubscriptionRelationshipDefinition'


// TODO: move these to iam-pure or permissions-pure or something
export * from './src/domain/relationships/common'
        import * as me from '@punnet/subscription-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/punnet/stacks/subscription/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/subscription-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    
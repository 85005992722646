export * from './src/event-kit'

        import * as me from '@punnet/core-event-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/punnet/stacks/core-event/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/core-event-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    
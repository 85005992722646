export * from './src/SubscriptionController'
        import * as me from '@punnet/subscription-web-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/punnet/stacks/subscription/web-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/subscription-web-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    
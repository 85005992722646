export * from './src/plans/PlanConfig'
export * from './src/plans/model'
export * from './src/utils/plan-config-domain-mapper'
        import * as me from '@punnet/product-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/punnet/stacks/product/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/product-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    